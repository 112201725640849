<template>
	<div v-if="dataInfo.title" class="template-detail" v-title="dataInfo.title+'——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box" v-if="dataInfo.vrUrl">
            <iframe
              :src="dataInfo.vrUrl"
              width="100%"
              height="100%"
              frameborder="0"
              scrolling="auto"
            >
            </iframe>
        </div>
        <div v-else class="header">
            <div class="header-pc">
                <img class="bran" src="../image/template/header_pc.jpg" alt="" />
            </div>
            <div class="header-mob">
                <img
                    class="bran"
                    src="../image/template/header_mob.jpg"
                    alt=""
                />
            </div>
        </div>
        
        <div class="content ">
			<div class="left f-y-s-c">
                <div class="info f-y-s-c">
                   <span class="title">{{dataInfo.title}}</span>
                   <div class="house-box f-x-b-c">
                        <div  class="item f-y-s-c">
                            <span class="item-label" >位置</span>
                            <span class="item-val" >{{ formartRoom(dataInfo.salesRoomNo) || '总旗舰店' }}</span>
                        </div>
                        <div  class="item f-y-s-c">
                            <span class="item-label" >风格</span>
                            <span class="item-val" >{{
                                formartDesStyle(dataInfo.designStyle)
                            }}</span>
                        </div>
                        <div  class="item f-y-s-c">
                            <span class="item-label" >户型</span>
                            <span class="item-val" >{{
                                formartHouseStyle(dataInfo.houseType)
                            }}</span>
                        </div>
                        <div  class="item f-y-s-c">
                            <span class="item-label" >面积</span>
                            <span class="item-val" >{{ formartHouseArea(dataInfo.houseArea) }}</span>
                        </div>
                        
                            
                        <!-- <HouseInfo type="column" :data="dataInfo" textColor="#241934;" ></HouseInfo> -->
                   </div>
                   <div class="content1" v-html="dataInfo.content"></div>
                   <div class="options f-x-c-c">
                        <div @click="navToDetail(preBtnInfo.id)" v-if="preBtnInfo.show" class="btn ">上一篇:{{preBtnInfo.title}}</div>
                        <div @click="navToDetail(nextBtnInfo.id)" v-if="nextBtnInfo.show" class="btn ">下一篇：{{nextBtnInfo.title}}</div>
                   </div>
                </div>
                <div class="activity">
                    <Activity></Activity>
                </div>
                <div v-if="loveList.length" class="about f-y-s-c">
                    <div class="title f-x-s-c">
                        <span class="text1">猜你喜欢</span>
                        <div class="cus-line"></div>
                    </div>
                    <div class="list">
                        
                        <div 
                            v-for="(item,index) in loveList.slice(0,4)"
                            :key="index"
                            class="item f-y-e-c"
                            :style="`background:center/cover url(${item.coverUrl})`"
                            @click="navToDetail(item.id)"
                            >
                            <img class="img" src="../image/index/index_sample.png" alt="">
                            <div class="text2 ">{{item.title}}</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="right">
                <div v-if="curDesList.length" class="info f-y-c-c">
                    <span class="title">推荐设计师</span>
                    <div class="des-list f-x-b-c">
                        <div v-if="curDesList[0]" @click="navToDesignDet(curDesList[0].id)" class="item f-y-c-c">
                            <img class="des-img" :src="curDesList[0].headerImgUrl" alt="">
                            <span class="text1">{{curDesList[0].name}}</span>
                            <span class="text2">{{formartInfo('ranks',curDesList[0].ranks)}}</span>
                        </div>
                        <div v-if="curDesList[1]"  @click="navToDesignDet(curDesList[1].id)" class="item f-y-c-c">
                            <img class="des-img" :src="curDesList[1].headerImgUrl" alt="">
                            <span class="text1">{{curDesList[1].name}}</span>
                            <span class="text2">{{formartInfo('ranks',curDesList[1].ranks)}}</span>
                        </div> 
                    </div>
                </div>
                <div class="from">
                    <VisitorForm title="免费装修报价" desc="我家按本案例装修需要多少钱？" adUnitCode="ksiC"></VisitorForm>
                </div>
                <div v-if="otherTemList.length" class="recommend" ref="refRecommend">
                        <span class="text4">该门店实景样板间</span>
                        <div
                            v-for="(item,index) in otherTemList.slice(0,3)"
                            :key="index" 
                            :class="index==0? 'no-border':''"
                            :style="`{border-top:}`"
                            class="recommend-item"
                            @click="navToDetail(item.id)"
                            >
                            <img class="img" :src="item.coverUrl" alt="">
                            <div class="text1 eps1">{{item.title}}</div>
                        </div>
                </div>
                <transition name="el-fade-in"  mode="out-in">
                    <div v-if="showFixedForm" class="from-fixed">
                        <VisitorForm title="免费装修报价" desc="我家按本案例装修需要多少钱？" adUnitCode="ksiC"></VisitorForm>
                    </div>
                </transition>
            </div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import HouseInfo from '../components/HouseInfo.vue'
import Activity from '../components/Activity.vue'
import VisitorForm from '../components/VisitorForm.vue'
import { getTemplateDetail } from '@/api/template.js'
import { useSalesRoomDict,useDesDict,useTemDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'

export default {
	components: {
		NavBar,
		Footer,
        HouseInfo,
        Activity,
        VisitorForm
	},
	data() {
		return {
            test:'123',
            desList:[],  //所有设计师列表
            ranksList:[],
            storeList:[],
            otherTemList:[],
            loveList:[],
            curId:'',
            dataInfo:{},
            templateDict:[],
            preBtnInfo:{
                show:false,
            },
            nextBtnInfo:{
                show:false,
            },
            params:{},
            curDesList:[], //推荐设计师列表

            salesRoomList: [],
			desStyleList: [],
			housetypeList: [],
			houseAreasList: [],
            showFixedForm:false,
		}
	},
    async mounted(){
        this.init()
        window.addEventListener("scroll", this.scrollToTop);
    },
	methods: {
        async init(){
            this.curId = this.$route.query.id
            const {salesRoomNo,houseType,designStyle} = this.$route.query
            this.params = {
                size:100,
                current:1,
                salesRoomNo,
                houseType,
                designStyle
            }
            this.ranksList = await useDesRankDict()
            this.storeList = await useSalesRoomDict()
            this.templateDict = (await useTemDict(this.params)).filter(item=>item.areaCode ==this.curCityInfo.value)
            if(this.curCityInfo.value=='00'){
                this.desList = (await useDesDict()).filter(item=>item.isHeadOffice ==1)
            }else{
                this.desList = (await useDesDict()).filter(item=>item.areaCode ==this.curCityInfo.value)
            }
            

            this.otherTemList = this.templateDict.filter(item=>{
                if(item.id!=this.curId && !item.isRecommend ) return true
            })
            this.loveList = this.templateDict.filter(item=>{
                if(item.id!=this.curId && item.isRecommend ) return true
            })
            this.getDetailData()
            this.setBtnData()

            this.salesRoomList = await useSalesRoomDict()
            this.desStyleList = await useDesStyleDict()
            this.housetypeList = await useHousetypeDict()
            this.houseAreasList = await useHouseAreasDict()
        },
        scrollToTop(){
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            let height = 2068 
            if(this.$refs.refRecommend){
                const recHeight =  this.$refs.refRecommend.clientHeight
                height += recHeight
            }
            if (scrollTop >= height) {
                this.showFixedForm = true;
            } else  {
                this.showFixedForm = false;
            }
        },
        navToDetail(id){
            if(id=='-1') return 
            this.$router.replace({
                path:'/template/detail',
                query:{
                    id,
                    ...this.params
                }
            })
        },
        navToDesignDet(id){
            this.$router.push({
                path:'/design/detail',
                query:{
                    id,
                }
            })
        },
        setBtnData(){
            const index = this.templateDict.findIndex(item=>item.id==this.curId)
            if(index!=-1){
                if(index!=0){
                    this.preBtnInfo = {
                        id:this.templateDict[index-1].id,
                        title:this.templateDict[index-1].title,
                        show:true
                    }
                }else{
                    this.preBtnInfo = {
                        id:-1,
                        title:'无',
                        show:true
                    }
                }
                if(index!=(this.templateDict.length-1)){
                    this.nextBtnInfo = {
                        id:this.templateDict[index+1].id,
                        title:this.templateDict[index+1].title,
                        show:true
                    }
                }else{
                    this.nextBtnInfo = {
                        id:-1,
                        title:'无',
                        show:true
                    }
                }
                
            }
        },
        async getDetailData(){
            try{
                const res = await getTemplateDetail(this.curId)
                if(res.code==0){
                    this.dataInfo = res.data
                    this.setCurDesList(this.dataInfo.designerId,this.dataInfo.salesRoomNo)
                }
            }catch(err){
                console.log(err);
            }
        },
        setCurDesList(designerId,salesRoomNo){
            const list1 = this.desList.filter(item=>item.id==designerId)
            const list2 = this.desList.find(item=>{
                if(item.showRoomNo==salesRoomNo && item.id!=designerId){
                    return true
                }
            })
            this.curDesList = [...list1,list2]
        },
        formartInfo(type,data){
            if(type=='ranks'){
                const res = this.ranksList.find(item=>item.value==data)
                if(res) return res.label
                else return data
            }
            if(type=='store'){
                const res = this.storeList.find(item=>item.showRoomNo==data)
                if(res) return res.showRoomName
                else return data
            }
        },
        //格式化门店
		formartRoom(value) {
			var re = ''
			this.salesRoomList.forEach((obj) => {
				if (obj.showRoomNo === value) {
					re = obj.showRoomName
				}
			})
			return re
		},
		//格式化设计风格
		formartDesStyle(value) {
			var re = ''
			this.desStyleList.forEach((obj) => {
				if (obj.value === value) {
					re = obj.label
				}
			})
			return re
		},

		//格式化户型类型
		formartHouseStyle(value) {
			var re = ''
			this.housetypeList.forEach((obj) => {
				if (obj.value === value) {
					re = obj.label
				}
			})
			return re
		},

		//格式化面积类型
		formartHouseArea(value) {
			var re = ''
			this.houseAreasList.forEach((obj) => {
				if (obj.value == value) {
					re = obj.label
				}
			})
			return re
		},
        

    },
    beforeDestroy(){
        window.removeEventListener("scroll",this.scrollToTop)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .template-detail {
        overflow-x: hidden;
		height: 100%;
        .box{
            width: 100%;
			height: 300px;
			margin-top: 44px;
        }
        .header{
            .header-pc{
                display: none;
            }
            .header-mob{
                margin-top: 43px;
                width: 100%;
			    height: 160px;
                .bran {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
        }
		
		.content {
            display: flex;
            justify-content: space-between;
            flex-flow: column;
			padding: 0 10px 30px 10px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #ffffff;
            .left{
                width:100%;
                margin-top: 50px;
                .info{
                    width: 100%;
                    .title{
                        text-align: center;
                        font-weight: bold;
                        font-size: 24px;
                        color: #241934;
                        line-height: 32px;
                        width: 100%;
                    }
                    .house-box{
                        margin-top: 15px;
                        width: 320px;
                        height: 50px;
                        background: #F1F0F2;
                        border-radius: 2px;
                        padding: 8px 20px;
                        box-sizing: border-box;

                        .item {
                            height: 100%;
                            min-width: 50px;
                            // margin-right: 20px;
                            .item-label {
                                
                                font-weight: 400;
                                font-size: 10px;
                                color: #666666;
                            }
                            .item-val {
                                margin-top: 3px;
                                
                                font-weight: 400;
                                font-size: 13px;
                                color: #241934;
                                white-space: nowrap;
                            }
                        }

                    }
                    .content1{
                        width: 100%;
                        font-size: 13px;
                        margin-top: 20px;
                        
                        /deep/ p,/deep/ strong,/deep/ span{
                            font-size: 13px;
                            
                        }
                        /deep/img{
                            max-width: 100%;
                        }
                        /deep/ .ql-align-right{
                            text-align: right;
                        }
                        /deep/ .ql-align-center{
                            text-align: center;
                        }
                        
                    }
                    .options{
                        margin-top: 20px;
                        width: 100%;
                        .btn:first-child{
                            margin-right: 10px;
                        }
                        .btn{
                            cursor: pointer;
                            width: 160px;
                            height: 35px;
                            line-height: 35px;
                            background: #F5F4F4;
                            border-radius: 20px;
                            border: 1px solid #E5E5E5;

                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #221732;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                   
                        
                }
                .about{
                    display: none;
                }
                .activity{
                    margin-top: 50px;
                    width: 100%;
                }
            }
            .right{
                width: 100%;
                margin-top: 50px;
                .info{
                    width: 100%;
                    // height: 200px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 5px;
                    padding: 20px 60px;
                    box-sizing: border-box;
                    .title{
                        
                        font-weight: bold;
                        font-size: 16px;
                        color: #140626;
                    }
                    .des-list{
                        margin-top:10px;
                        width: 100%;
                        .item{
                            cursor: pointer;
                            .des-img{
                                width: 70px;
                                height: 70px;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                            .text1{
                                
                                font-weight: bold;
                                font-size: 13px;
                                color: #140626;
                                margin-top: 5px;
                            }
                            .text2{
                                
                                font-weight: 400;
                                font-size: 13px;
                                color: #5D5665;
                                margin-top: 3px;
                            }
                        }
                    }
                }
                .from{
                    width: 100%;
                    // padding: 0 40px;
                    box-sizing: border-box;
                    margin-top: 50px;
                }
                .from-fixed{
                    display: none;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 5px;
                    margin-top: 50px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 18px;
                        color: #140626;
                        line-height: 18px;
                        margin: 15px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 20px 0;
                        box-sizing: border-box;
                        width: 100%;
                        // height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            object-fit: cover;
                            width: 100%;
                            height: 200px;
                            border-radius: 2px;
                        }
                        .text1{
                            
                            font-weight: 400;
                            font-size: 14px;
                            color: #140626;
                            line-height: 14px;
                            margin-top: 10px;
                            width: 100%;
                        }
                    }
                }
            }
           
		}
	}
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.template-detail {
		height: 100%;
        .box{
            width: 100%;
			height: 660px;
			// margin-top: 96px;
        }
        .header{
            .header-pc{
                width: 100%;
			    height: 230px;
                .bran {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .header-mob{
                display: none;
            }
            
        }
		
		.content {
			padding: 0 2% 60px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
			
            .left{
                width: 1020px;
                // flex: 1;
                margin-right: 67px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    .title{
                        font-weight: bold;
                        font-size: 60px;
                        color: #241934;
                        line-height: 85px;
                        width: 100%;
                        text-align: center;
                    }
                    .house-box{
                        margin-top: 36px;
                        width: 894px;
                        height: 130px;
                        background: #F1F0F2;
                        border-radius: 4px;
                        padding: 0 60px;
                        box-sizing: border-box;
                        .item {
                            min-width: 100px;
                            margin-right: 50px;
                            height: 87px;
                            .item-label {
                                
                                font-weight: 300;
                                font-size: 24px;
                                color: #666666;
                            }
                            .item-val {
                                margin-top: 16px;
                                
                                font-weight: 300;
                                font-size: 30px;
                                color: #241934;
                                white-space: nowrap;
                            }
                        }

                    }
                    .content1{
                        width: 100%;
                        font-size: 14px;
                        margin-top: 65px;
                        
                        /deep/ p,/deep/ strong,/deep/ span{
                            font-size: 20px;
                            
                        }
                        /deep/img{
                            max-width: 100%;
                        }
                        /deep/ .ql-align-right{
                            text-align: right;
                        }
                        /deep/ .ql-align-center{
                            text-align: center;
                        }
                        
                    }
                    .options{
                        margin-top: 70px;
                        width: 100%;
                        .btn:first-child{
                            margin-right: 30px;
                        }
                        .btn{
                            cursor: pointer;
                            width: 458px;
                            height: 80px;
                            line-height: 80px;
                            background: #F5F4F4;
                            border-radius: 40px;
                            border: 1px solid #E5E5E5;

                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #221732;
                            padding: 0 38px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                   
                        
                }
                .about{
                    width: 100%;
                    margin-top: 60px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #6C1EC8;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,49%);
                        .item:nth-child(n<2){
                            margin-bottom: 40px;
                        }
                        .item{
                            width: 100%;
                            height:320px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 10px;
                            overflow: hidden;
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            .img{
                                width: 185px;
                                height: 185px;
                                opacity: 0.8;
                            }
                            .text2{
                                margin-top: 30px;
                                width: 100%;
                                height: 80px;
                                background: rgba(0, 0, 0, 0.5);
                                border-radius: 0 0 10px 10px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                box-sizing: border-box;
                                padding: 0 30px;
                                text-align: center;
                                line-height: 80px;
                            }
                            
                        }
                    }
                }
                .activity{
                    margin-top: 60px;
                    width: 100%;
                }
            }
            .right{
                width: 469px;
                // height: 800px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    height: 426px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    padding: 30px;
                    box-sizing: border-box;
                    .title{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                    }
                    .des-list{
                        margin-top:28px;
                        width: 100%;
                        .item{
                            cursor: pointer;
                            .des-img{
                                width: 194px;
                                height: 194px;
                                border-radius: 50%;
                            }
                            .text1{
                                
                                font-weight: bold;
                                font-size: 30px;
                                color: #140626;
                                margin-top: 23px;
                            }
                            .text2{
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #5D5665;
                                margin-top: 13px;
                            }
                        }
                    }
                }
                .from{
                    width: 100%;
                    margin-top: 60px;
                }
                .from-fixed{
                    width: 100%;
					// margin-top: 130px;
                    position: sticky;
                    top: 30px;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    margin-top: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                        line-height: 36px;
                        margin: 30px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 40px 0;
                        box-sizing: border-box;
                        width: 100%;
                        // height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 410px;
                            height: 297px;
                            border-radius: 4px;
                        }
                        .text1{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #140626;
                            line-height: 30px;
                            margin-top: 16px;
                            width: 100%;
                        }
                    }
                }
            }
           
		}
	}
}
@media screen and (min-width: 1360px) {
	.template-detail {
		height: 100%;
        .box{
            width: 100%;
			height: 660px;
			// margin-top: 96px;
        }
        .header{
            .header-pc{
                width: 100%;
			    height: 230px;
                .bran {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .header-mob{
                display: none;
            }
            
        }
		.content {
			padding: 0 180px 60px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
			
            .left{
                // width: 1020px;
                width: 1020px;
                // flex: 1;
                margin-right: 67px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    .title{
                        font-weight: bold;
                        font-size: 60px;
                        color: #241934;
                        line-height: 85px;
                        width: 100%;
                        text-align: center;
                    }
                    .house-box{
                        margin-top: 36px;
                        width: 894px;
                        height: 130px;
                        background: #F1F0F2;
                        border-radius: 4px;
                        padding: 0 60px;
                        box-sizing: border-box;
                        .item {
                            min-width: 100px;
                            margin-right: 50px;
                            height: 87px;
                            .item-label {
                                
                                font-weight: 300;
                                font-size: 24px;
                                color: #666666;
                            }
                            .item-val {
                                margin-top: 16px;
                                
                                font-weight: 300;
                                font-size: 30px;
                                color: #241934;
                                white-space: nowrap;
                            }
                        }

                    }
                    .content1{
                        width: 100%;
                        font-size: 14px;
                        margin-top: 65px;
                        
                        /deep/ p,/deep/ strong,/deep/ span{
                            font-size: 20px;
                            
                        }
                        /deep/img{
                            max-width: 100%;
                        }
                        /deep/ .ql-align-right{
                            text-align: right;
                        }
                        /deep/ .ql-align-center{
                            text-align: center;
                        }
                        
                    }
                    .options{
                        margin-top: 70px;
                        width: 100%;
                        .btn:first-child{
                            margin-right: 30px;
                        }
                        .btn{
                            cursor: pointer;
                            width: 458px;
                            height: 80px;
                            line-height: 80px;
                            background: #F5F4F4;
                            border-radius: 40px;
                            border: 1px solid #E5E5E5;

                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #221732;
                            padding: 0 38px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                   
                        
                }
                .about{
                    width: 100%;
                    margin-top: 60px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #6C1EC8;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,49%);
                        .item:nth-child(n<2){
                            margin-bottom: 40px;
                        }
                        .item{
                            width: 100%;
                            height:320px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 10px;
                            overflow: hidden;
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            .img{
                                width: 185px;
                                height: 185px;
                                opacity: 0.8;
                            }
                            .text2{
                                margin-top: 30px;
                                width: 100%;
                                height: 80px;
                                background: rgba(0, 0, 0, 0.5);
                                border-radius: 0 0 10px 10px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                box-sizing: border-box;
                                padding: 0 30px;
                                text-align: center;
                                line-height: 80px;
                            }
                            
                        }
                    }
                }
                .activity{
                    margin-top: 60px;
                    width: 100%;
                }
            }
            .right{
                width: 469px;
                // height: 800px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    height: 426px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    padding: 30px;
                    box-sizing: border-box;
                    .title{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                    }
                    .des-list{
                        margin-top:28px;
                        width: 100%;
                        .item{
                            cursor: pointer;
                            .des-img{
                                width: 194px;
                                height: 194px;
                                border-radius: 50%;
                            }
                            .text1{
                                
                                font-weight: bold;
                                font-size: 30px;
                                color: #140626;
                                margin-top: 23px;
                            }
                            .text2{
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #5D5665;
                                margin-top: 13px;
                            }
                        }
                    }
                }
                .from{
                    width: 100%;
                    margin-top: 60px;
                }
                .from-fixed{
                    width: 100%;
					// margin-top: 130px;
                    position: sticky;
                    top: 30px;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    margin-top: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                        line-height: 36px;
                        margin: 30px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 40px 0;
                        box-sizing: border-box;
                        width: 100%;
                        // height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 410px;
                            height: 297px;
                            border-radius: 4px;
                        }
                        .text1{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #140626;
                            line-height: 30px;
                            margin-top: 16px;
                            width: 100%;
                        }
                    }
                }
            }
           
		}
	}
}
</style>
